.projects-wrapper {
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5vh 5vh;
}

.projects-header {
  font-size: 4vw;
  font-weight: 600;
  margin-bottom: 3vh;
  text-shadow: 5px 5px rgba(0, 0, 0, 1);
}

.projects-list-container {
  display: flex;
  flex-direction: column;
}

/* Mobile */
.projects-wrapper-mobile {
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8vh;
}

.projects-header-mobile {
  font-size: 8vw;
  font-weight: 600;
  margin-bottom: 3vh;
}

.projects-list-container-mobile {
  display: flex;
  flex-direction: column;
}
