.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-20vh);
}

.image-container-firefox {
  transform: translateY(-5vh);
}

.image {
  max-height: 100vh;
  zoom: 1.2;
}

/* progressive image loading */
.full {
  transition: opacity 400ms ease 0ms;
}

.thumbnail {
  filter: blur(20px);
  transform: scale(1.1);
  transition: visibility 0ms ease 400ms;
}
