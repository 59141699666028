.show-arrow {
  position: fixed;
  z-index: 100;
  bottom: 3vh;
  right: 0.75vw;
  cursor: pointer;
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
}

.hide-arrow {
  opacity: 0;
  position: fixed;
}

.arrow-wrapper {
  color: #ffffff;
  height: 5vh;
  width: 5vw;
  transition: opacity 0.1s;
}

.arrow-wrapper:hover {
  opacity: 0.5;
}

/* Mobile */
.show-arrow-mobile {
  position: fixed;
  z-index: 100;
  bottom: 0;
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
  left: 50%;
  transform: translateX(-50%);
}

.hide-arrow-mobile {
  opacity: 0;
  position: fixed;
}

.arrow-wrapper-mobile {
  color: #ffffff;
  height: 15vh;
  width: 15vw;
  opacity: 0.5;
  transition: opacity 0.1s;
}
