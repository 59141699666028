.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5vw;
  height: 5vw;
  margin-right: 0.8vw;
  margin-left: 0.8vw;
  border-radius: 50%;
  border: 0.2em solid #ffffff;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}

.icon {
  height: 1.7vw;
  width: 1.7vw;
}

.button:after {
  position: absolute !important;
  content: "";
  width: 4vw;
  height: 4vw;
  position: relative;
  border-radius: 50%;
  background-color: #ffffff;
  opacity: 0;
  transform: scale(0.5, 0.5);
  transition: all 0.3s ease;
}

.button:hover:after {
  opacity: 0.5;
  transform: scale(1, 1);
}

/* Mobile */
.button-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20vw;
  height: 20vw;
  margin: 0.8vw 0.8vw;
  border-radius: 50%;
  border: 0.2em solid #ffffff;
  text-align: center;
  color: #ffffff;
}

.icon-mobile {
  height: 8vw;
  width: 8vw;
}
