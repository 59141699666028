/* Desktop & Tablet */
.landing-wrapper {
  color: #ffffff;
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%;
  font-weight: 500;
}

.landing-left-side {
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  animation: slideInFromLeft 1.2s ease-out 0.5s forwards;
  opacity: 0;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-10%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.name-intro {
  font-size: 4em;
}

.tagline {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 2em;
  margin-bottom: 4vh;
}

.landing-right-side {
  width: auto;
  overflow: hidden;
}

.skewed {
  position: absolute;
  width: 100%;
  height: 20vh;
  bottom: 0;
  background: #4d8f6d;
  z-index: 0;
  transform: skewY(5deg) translateY(10%);
  transform-origin: top left;
}

/* Mobile */
.landing-wrapper-mobile {
  display: flex;
  color: #ffffff;
  height: 100vh;
  width: 100%;
  font-weight: 500;
}

.landing-border-mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: #ffffff solid 3px;
  margin: 2vh 2vh;
}

.name-intro-mobile {
  font-size: 12vw;
}

.tagline-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 5vw;
}
