.about-me-wrapper {
  height: 120vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #4d8f6d;
  z-index: 5;
  color: #ffffff;
}

.page-header {
  font-size: 4em;
  animation: fadeInFromLeft 0.8s ease-in-out 0.2s both;
  opacity: 0;
}

@keyframes fadeInFromLeft {
  from {
    transform: translateX(-20%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.vertical-divider {
  border-left: 0.3em solid #ffffff;
  height: 20vh;
  margin-left: 4vw;
  margin-right: 4vw;
  animation: expandLine 0.8s ease-in 0.2s both;
}

@keyframes expandLine {
  from {
    height: 0;
  }
  to {
    height: 20vh;
  }
}

.description {
  display: flex;
  align-items: center;
  height: 50vh;
  width: 45vw;
  font-size: 1.5em;
  opacity: 0;
  animation: fadeInFromRight 0.8s ease-in-out 0.2s both;
}

@keyframes fadeInFromRight {
  from {
    transform: translateX(20%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Mobile */
.about-me-wrapper-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 5;
  color: #ffffff;
  width: 100vw;
}

.page-header-mobile {
  font-size: 8vw;
  margin-top: 5vh;
  font-weight: 600;
  margin-bottom: 3vh;
}

.description-mobile {
  background-color: #4d8f6d;
  font-size: 5vw;
  margin: 4vh 2vw;
  padding: 3vh 5vw;
  border-radius: 5px;
}
