.a-project-wrapper {
  background-color: #4d8f6d;
  display: flex;
  flex-direction: column;
  font-size: 1.2em;
  margin: 2vh 5vw;
  padding: 3vh 3vw;
  border-radius: 25px;
  transition: background-color 0.1s;
  box-shadow: 10px 10px 0px 0px rgba(0, 0, 0, 1);
}

.a-project-wrapper:hover {
  background-color: #184e68;
}

.a-project-top-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.3vh solid #ffffff;
  margin-bottom: 1vh;
}

.a-project-top-wrapper-left-side {
  display: inline;
}

.a-project-title {
  font-size: 1.9em;
}

.timeframe {
  font-size: 1.2em;
}

.a-project-description a {
  color: #c261a3;
}

.a-project-description a:hover {
  color: #8f4d7a;
}

.a-project-technologies {
  font-weight: 700;
  margin-top: 2vh;
}

/* Mobile */
.a-project-wrapper-mobile {
  background-color: #4d8f6d;
  display: flex;
  flex-direction: column;
  font-size: 4vw;
  transition: background-color 0.1s;
  margin: 4vh 2vw;
  padding: 2vh 5vw;
  border-radius: 5px;
}

.a-project-wrapper-mobile:hover {
  background-color: #184e68;
}

.a-project-top-wrapper-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 0.3vh solid #ffffff;
  margin-bottom: 1vh;
}

.a-project-top-wrapper-left-side-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.timeframe-mobile {
  font-size: 4vw;
  margin-bottom: 1vh;
}

.a-project-title-mobile {
  font-size: 5vw;
  font-weight: 700;
}

.a-project-description-mobile a {
  color: #c261a3;
}

.a-project-description-mobile a:hover {
  color: #8f4d7a;
}

.a-project-technologies-mobile {
  font-weight: 700;
  margin-top: 2vh;
}
